<template>
  <validation-provider
    vid
    v-slot="{ errors }"
    :name="localFormData.text"
    :rules="localFormData.rules"
  >
    <tiptap-vuetify
      class="mb-5"
      v-model="localItem[localFormData.name]"
      :extensions="extensions"
      :error-messages="errors"
      :placeholder="localFormData.text"
  /></validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "RichTextAreaField",
  mixins: [validate],
  components: { TiptapVuetify },
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    },
    formData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    localItem() {
      return this.item || this.defaultFormData;
    },

    localFormData() {
      return this.formData || this.defaultFormData;
    }
  },
  methods: {
    elementClicked(element) {
      this.$emit("clicked-element-update", element);
    }
  },
  data() {
    return {
      defaultFormData: {
        value: "",
        text: "Textarea field",
        placeholder: "Textarea field",
        name: "Textarea field",
        fieldName: "TextareaField",
        type: "TextareaField",
        readonly: false,
        required: false,
        rows: 4,
        class: ""
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ]
    };
  }
};
</script>
