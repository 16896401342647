<template>
  <validation-provider
    :vid="localFormData.name"
    v-slot="{ errors }"
    :name="localFormData.text"
    :rules="localFormData.rules"
  >
    <v-switch
      class="no-drag align-center justify-content-center"
      :readonly="localFormData.readonly"
      v-model="localItem[localFormData.name]"
      :placeholder="localFormData.placeholder"
      :label="localFormData.text"
      :error-messages="errors"
    ></v-switch>
  </validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  name: "SwitchField",
  mixins: [validate],
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    formData: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    }
  },
  computed: {
    localFormData() {
      return this.formData || this.defaultFormData;
    },
    localItem() {
      return this.item || this.defaultFormData;
    }
  },
  methods: {
    elementClicked(element) {
      this.$emit("clicked-element-update", element);
    }
  },
  data() {
    return {
      defaultFormData: {
        value: false,
        text: "Switch field",
        placeholder: "Switch field",
        name: "Switch field",
        fieldName: "SwitchField",
        type: "SwitchField",
        readonly: false,
        required: false,
        class: ""
      }
    };
  }
};
</script>
