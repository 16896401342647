<template>
  <validation-provider
    :vid="localFormData.name"
    v-slot="{ errors, pending }"
    :name="localFormData.text"
    :rules="localFormData.rules"
  >
    <v-text-field
      autocomplete="off"
      v-model="localItem[localFormData.name]"
      :placeholder="localFormData.placeholder"
      :label="localFormData.text"
      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      :name="localFormData.text"
      :loading="pending"
      :type="showPassword ? 'text' : 'password'"
      :error-messages="errors"
      @click:append="showPassword = !showPassword"
      clearable
      solo
      :dense="localFormData.dense"
      :autofocus="localFormData.autofocus"
    >
    </v-text-field>
  </validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  name: "PasswordField",
  mixins: [validate],
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    },
    invalid: {
      required: false
    },
    formData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    localFormData() {
      return this.formData;
    },
    localItem() {
      return this.item;
    }
  },
  methods: {},
  data() {
    return {
      showPassword: false
    };
  }
};
</script>
