import StandardTextField from "./StandardTextField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import SwitchField from "./SwitchField";
import DateField from "./DateField";
import RichTextAreaField from "./RichTextAreaField";
import FileUploadField from "./FileUploadField";
import ProductField from "./ProductField";
import PasswordField from "./PasswordField";
export default {
  StandardTextField,
  CheckboxField,
  RadioField,
  SwitchField,
  DateField,
  SelectField,
  RichTextAreaField,
  FileUploadField,
  ProductField,
  PasswordField
};
