<template>
  <validation-provider
    vid
    v-slot="{ errors, pending }"
    :name="localFormData.text"
    :rules="localFormData.rules"
  >
    <v-autocomplete
      autocomplete="off"
      :items="localFormData.items"
      :item-value="localFormData.itemValue || 'id'"
      :item-text="localFormData.itemText || 'name'"
      :multiple="localFormData.multiple ? localFormData.multiple : false"
      small-chips
      :loading="pending"
      @change="onChange($event)"
      deletable-chips
      :disabled="localFormData.disabled ? localFormData.disabled : false"
      :return-object="localFormData.returnObject ? localFormData.returnObject : false"
      v-model="localItem[localFormData.name]"
      :placeholder="localFormData.placeholder"
      :error-messages="errors"
      :label="localFormData.text"
      clearable
      solo
      :dense="localFormData.dense"
    >
      <template v-slot:prepend-item v-if="localFormData.multiple && !localFormData.hidecheckall">
        <v-list-item>
          <v-btn @click="checkAllOptions()" text>{{ $t("app.buttonCheckAll") }}</v-btn>
          <v-btn @click="uncheckAllOptions()" text>{{ $t("app.buttonUncheckAll") }}</v-btn>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index < 1" class="my-2 mr-2">
          <span>{{ item[localFormData.itemText || "name"] }}</span>
        </v-chip>
        <span
          v-if="index === 1 && localItem[localFormData.name].length > 1"
          class="grey--text text-caption"
          >(+{{ localItem[localFormData.name].length - 1 }})
        </span>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  mixins: [validate],
  components: {},
  name: "SelectField",
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    },
    formData: {
      type: Object,
      required: false,
      default: null
    }
  },
  created() {},
  computed: {
    localFormData() {
      return this.formData || this.defaultFormData;
    },
    localItem() {
      return this.item || this.defaultFormData;
    }
  },
  methods: {
    checkAllOptions() {
      let items = [...this.localFormData.items];
      if (!this.localFormData.returnObject) {
        items = items.map((item) => item[this.localFormData.itemValue || "id"]);
      }
      this.localItem[this.localFormData.name] = items;
      Event.$emit("select-field-changed-" + this.localFormData.name, items);
    },
    uncheckAllOptions() {
      this.localItem[this.localFormData.name] = [];
      Event.$emit("select-field-changed-" + this.localFormData.name, []);
    },
    onChange(value) {
      Event.$emit("select-field-changed-" + this.localFormData.name, value);
    }
  },
  data() {
    return {
      defaultFormData: {
        value: "",
        text: "Select Field",
        placeholder: "Select Field",
        name: "Select Field",
        fieldName: "SelectField",
        type: "SelectField",
        readonly: false,
        multiple: true,
        hidecheckall: false,
        items: [],
        duplicate: false,
        duplicateText: "",
        dense: true,
        outlined: false,
        flat: false,
        rules: [],
        clearable: true,
        rounded: false,
        autofocus: false,
        filled: false,
        maxSelection: null,
        solo: true,
        required: false,
        class: ""
      }
    };
  }
};
</script>
