<template>
  <v-card>
    <v-card-title>
      <span class="d-flex justify-left">
        <v-btn icon @click="cancel" v-if="isMobile">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </span>
      <span class="d-flex" :class="isMobile ? ' ml-12' : ''" v-if="title">
        <v-icon left v-if="icon">{{ icon }}</v-icon>
        {{ title }}</span
      >
    </v-card-title>
    <v-spacer></v-spacer>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div>
        <v-row align="center" no-gutters>
          <v-col
            :class="data.class"
            class="px-5 py-1"
            v-for="(data, index) in formData"
            :key="index"
          >
            <component
              v-bind:is="data.fieldName"
              :item="item"
              :form-data="data"
              :invalid="invalid"
            ></component>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn text plain medium @click.native="cancel">
          <v-icon size="20" left>mdi-cancel</v-icon>
          {{ $t("app.cancel") }}
        </v-btn>
        <v-divider vertical class="mx-6"></v-divider>
        <v-btn
          text
          @click.native="submit"
          large
          :dark="!invalid"
          :class="invalid ? '' : color"
          :loading="loading"
          :disabled="invalid"
        >
          <v-icon size="20" left>mdi-content-save</v-icon>
          {{ $t("app.save") }}
        </v-btn>
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import components from "@/components/core/form/formElements";

export default {
  $_veeValidate: {
    validator: "new"
  },
  components: {
    ValidationObserver,
    ...components
  },
  name: "FormWizard",
  data() {
    return {
      date: null,
      submitUrl: this.storeAction
    };
  },
  created() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    loading: {
      get() {
        return this.$store.getters["options/loading"];
      }
    },
    textColor() {
      return this.$route.meta.textColor || "primary--text";
    },
    color() {
      return this.$route.meta.color || "primary";
    }
  },
  props: {
    item: {
      required: true
    },
    formData: {
      required: true
    },
    storeAction: {
      required: true
    },
    store: {
      required: true
    },
    title: {
      required: false
    },
    goTo: {
      required: false
    },
    namespace: {
      required: true
    },
    createFormData: {
      required: false,
      default: false
    },
    fetchUserData: {
      required: false,
      default: false
    },
    icon: {
      required: false
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    makeFormData(item) {
      const formData = new FormData();
      for (const key of Object.keys(item)) {
        if (key == "file") {
          formData.append("file", item[key], item[key].name);
        } else {
          formData.set(key, item[key]);
        }
      }
      return formData;
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let dataForApi = this.item;
        if (this.createFormData) {
          let fd = this.makeFormData(this.item);
          dataForApi = fd;
        }

        await this.$store.dispatch(this.storeAction, dataForApi).then(() => {
          if (this.fetchUserData) {
            this.$auth.fetch();
          }
          this.cancel();
        });
      } else {
        await this.$store.dispatch("options/setOptionsData", {
          message: "There is some form validation errors. Please fix the red form fields!",
          snackbar: true,
          color: "red"
        });
      }
    }
  }
};
</script>
