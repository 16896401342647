<template>
  <validation-provider
    vid
    v-slot="{ errors }"
    :name="localFormData.name"
    :rules="localFormData.rules"
  >
    <v-row no-gutters class="no-drag">
      <v-col cols="12" class="align-start justify-content-start">
        <div class="caption text-left">{{ localFormData.text }}</div>
      </v-col>

      <v-col v-for="item in localFormData.items" v-bind:key="item" class="pa-0">
        <v-checkbox
          :readonly="localFormData.readonly"
          :value="item"
          :error-messages="errors"
          v-model="localItem[localFormData.name]"
          :dark="darkMode"
          :multiple="localFormData.multiple"
          :placeholder="localFormData.placeholder"
          :label="item"
        ></v-checkbox>
      </v-col> </v-row
  ></validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  name: "CheckboxField",
  components: {},
  mixins: [validate],
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    formData: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    }
  },
  computed: {
    localItem() {
      return this.item || this.defaultFormData;
    },

    localFormData() {
      return this.formData || this.defaultFormData;
    }
  },
  methods: {
    elementClicked(element) {
      this.$emit("clicked-element-update", element);
    }
  },
  data() {
    return {
      defaultFormData: {
        value: "",
        text: "Checkbox field",
        placeholder: "Checkbox field",
        name: "Checkbox field",
        fieldName: "CheckboxField",
        type: "CheckboxField",
        readonly: false,
        tooltips: [],
        allowTooltips: false,
        multiple: true,
        items: ["Check 1", "Check2"],
        required: false,
        class: ""
      }
    };
  }
};
</script>
