<template>
  <validation-provider
    :vid="localFormData.name"
    v-slot="{ errors, pending }"
    :name="localFormData.text"
    :rules="localFormData.rules"
  >
    <v-text-field
      autocomplete="off"
      v-model="localItem[localFormData.name]"
      :placeholder="localFormData.placeholder"
      :label="localFormData.text"
      :name="localFormData.text"
      :counter="localFormData.counter ? localFormData.counter : false"
      :hint="localFormData.hint ? localocalFormDatalItem.hint : ''"
      :loading="pending"
      :error-messages="errors"
      clearable
      solo
      :dense="localFormData.dense"
      :autofocus="localFormData.autofocus"
    >
    </v-text-field>
  </validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  name: "StandardTextField",
  mixins: [validate],
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    },
    invalid: {
      required: false
    },
    formData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    localFormData() {
      return this.formData || this.defaultFormData;
    },
    localItem() {
      return this.item || this.defaultFormData;
    }
  },
  methods: {},
  data() {
    return {
      defaultFormData: {
        value: "",
        text: "Text field",
        placeholder: "Text field",
        name: "Text field",
        fieldName: "StandardTextField",
        type: "StandardTextField",
        readonly: false,
        dense: true,
        outlined: false,
        flat: false,
        clearable: true,
        rounded: false,
        autofocus: false,
        filled: false,
        solo: true,
        required: false,
        class: ""
      }
    };
  }
};
</script>
