<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider
          vid
          v-slot="{ errors }"
          :name="localFormData.name"
          :rules="localFormData.rules"
        >
          <v-text-field
            :value="formatValue()"
            :clearable="localFormData.clearable"
            :placeholder="localFormData.placeholder"
            :label="localFormData.text"
            :error-messages="errors"
            prepend-inner-icon="mdi-calendar"
            :readonly="localFormData.readonly"
            :outlined="localFormData.outlined"
            :flat="localFormData.flat"
            :filled="localFormData.filled"
            solo
            :dense="localFormData.dense"
            :autofocus="localFormData.autofocus"
            :rounded="localFormData.rounded"
            v-bind="attrs"
            v-on="on"
            @click:clear="localItem[localFormData.name] = null"
          ></v-text-field
        ></validation-provider>
      </template>
      <v-date-picker
        v-model="localItem[localFormData.name]"
        :label="localFormData.text"
        :type="localFormData.fullDate ? 'date' : 'month'"
        :active-picker.sync="activePicker"
        :readonly="localFormData.readonly"
        :max="localFormData.maxDate ? localFormData.maxDate : ''"
        :min="localFormData.minDate ? localFormData.minDate : ''"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  mixins: [validate],
  name: "DateField",
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    },
    formData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    localItem() {
      return this.item || this.defaultFormData;
    },
    localFormData() {
      return this.formData || this.defaultFormData;
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },
  methods: {
    elementClicked(element) {
      this.$emit("clicked-element-update", element);
    },
    save(date) {
      this.$emit("date-field-chanded", date);
      this.$refs.menu.save(date);
    },
    formatValue() {
      return this.formatDate(
        this.localItem[this.localFormData.name],
        this.localFormData.dateFormat
      );
    }
  },
  data() {
    return {
      defaultFormData: {
        value: null,
        text: "Date Field",
        placeholder: "Date Field",
        name: "Date Field",
        fieldName: "DateField",
        type: "DateField",
        readonly: true,
        required: false,
        fullDate: true,
        dateFormat: "YYYY-MM-DD",
        class: "",
        minDate: "1950-01-01",
        maxDate: "",
        dense: true,
        outlined: false,
        flat: false,
        clearable: true,
        rounded: false,
        autofocus: false,
        filled: false,
        solo: true
      },
      activePicker: null,
      menu: false
    };
  }
};
</script>
