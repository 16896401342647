<template>
  <input
    @change="uploadEvent(fileUpload, multiple)"
    type="file"
    ref="uploadFile"
    style="display: none"
    :accept="accept"
    :multiple="multiple ? true : false"
  />
</template>

<script>
import fileUploadMixin from "@/mixins/fileUpload";
export default {
  mixins: [fileUploadMixin],
  name: "FileUploadSimple",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: "image/*",
      required: false
    },
    fileUpload: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {
    Event.$on("files-uploaded", function (src) {
      Event.$emit("upload-file-update", src);
    });
    Event.$on("files-names", function (src) {
      Event.$emit("file-names-update", src);
    });
    Event.$on("files-removed", function (src) {
      Event.$emit("file-remove-update", src);
    });
  },
  methods: {
    uploadFile() {
      this.$refs.uploadFile.click();
    }
  }
};
</script>
