import { mapGetters } from "vuex";
export default {
  data() {
    return {
      uploadLoading: false,
      sizeLimit: 109700000,
      dragging: false,
      multipleFiles: false,
      uploadedFiles: [],
      uploadedFilesNames: []
    };
  },
  computed: {
    ...mapGetters({
      folderUpload: "files/folderUpload"
    })
  },
  methods: {
    async hendleUploadFiles(files) {
      for (const item of files) {
        const result = await this.$store.dispatch("files/uploadFilePublic", {
          item: item,
          folder: this.folderUpload
        });
        if (result) {
          !this.multipleFiles
            ? (this.uploadedFiles = [result.absolutePath])
            : this.uploadedFiles.push(result.absolutePath);
          !this.multipleFiles
            ? (this.uploadedFilesNames = [result.absolutePath])
            : this.uploadedFilesNames.push(result.absolutePath);
          let returnValue =
            new Set(this.uploadedFiles).size === 1 ? result.relativeFilePath : this.uploadedFiles;
          Event.$emit("files-uploaded", returnValue);
          Event.$emit("files-names", this.uploadedFilesNames);
          this.dragging = false;
        }
      }
    },
    uploadEvent(fileUpload = true, multiple = false) {
      this.uploadLoading = true;
      this.multipleFiles = multiple;
      if (this.$refs.uploadFile.files.length) {
        if (fileUpload) {
          this.hendleUploadFiles([...this.$refs.uploadFile.files]);
        } else {
          Event.$emit("files-uploaded", [...this.$refs.uploadFile.files]);
        }
        this.$refs.uploadFile.value = null;
      }
      this.uploadLoading = false;
    },
    removeFile() {
      Event.$emit("files-removed", this.fileKey);
    },
    onFileDrop(e, multiple) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      if (droppedFiles.length > 1 && !multiple) {
        alert("Only one file is allowed");
        return;
      }
      this.hendleUploadFiles(droppedFiles);
    }
  }
};
