<template>
  <v-card class="pa-4 mb-5">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <validation-provider
          vid
          v-slot="{ errors }"
          :name="localFormData.name"
          :rules="localFormData.rules"
        >
          <div class="grey--text text--darken-2">{{ localFormData.text }}</div>
          <v-divider style="width: 100px" class="my-3"></v-divider>
          <div class="grey--text text--darken-2">
            {{ localFormData.allowedFormatsText }} :
            <span v-for="(item, index) in localFormData.allowedFormats" v-bind:key="index"
              >{{ item }}
              <span v-if="index != localFormData.allowedFormats.length - 1">, </span></span
            >
          </div>
          <v-input :value.sync="localItem[localFormData.name]" :error-messages="errors"></v-input>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="6">
        <div
          :class="['dropZone', dragging ? 'dropZone-over' : '']"
          @dragover="dragging = true"
          @dragleave="dragging = false"
          @dragover.prevent
          @drop.prevent
          @drop="onFileDrop($event, localFormData.multiple)"
        >
          <div class="dropZone-info" @click="triggerUploadFile">
            <v-icon size="50">mdi-attachment</v-icon>
            <div class="grey--text text--darken-3">
              {{ localFormData.placeholder }}
            </div>

            <file-upload
              class="file-wrapper"
              ref="fileUploadComponent"
              :multiple="localFormData.multiple"
              :error-messages="errors"
              v-on:upload-file-update="updateItemSrc($event)"
              v-on:file-names-update="updateFileNames($event)"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="12" v-if="localFormData.showPreview">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(item, index) in localFormData.fileNames" :key="index" class="ma-2">
            <v-badge bordered overlap>
              <v-icon slot="badge" style="z-index: 1" @click.native="updateFileRemove(index)">
                mdi-close
              </v-icon>
              <v-card class="text-center">
                <v-img max-width="150" :src="item"></v-img>
              </v-card>
            </v-badge>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import FileUpload from "@/components/core/form/formElements/FileUploadSimple";
import fileUploadMixin from "@/mixins/fileUpload";
import validate from "@/mixins/validationMixin";
import { mapGetters } from "vuex";
export default {
  name: "FileUploadField",
  components: { FileUpload },
  mixins: [fileUploadMixin, validate],
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    formData: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    }
  },
  computed: {
    ...mapGetters({
      loading: "files/loading"
    }),
    localItem() {
      return this.item || this.defaultFormData;
    },
    localFormData() {
      return this.formData || this.defaultFormData;
    }
  },
  created() {
    let _this = this;
    Event.$on("files-uploaded", function (src) {
      _this.updateItemSrc(src);
    });
    Event.$on("files-names", function (names) {
      _this.updateFileNames(names);
    });
    this.$store.commit("files/setFolderUpload", this.localFormData.folderUpload);
  },
  methods: {
    elementClicked(element) {
      Event.$emit("clicked-element-update", element);
    },
    triggerUploadFile() {
      this.$refs.fileUploadComponent.uploadFile();
    },
    updateItemSrc(src) {
      if (this.localFormData.returnString) {
        this.localItem[this.localFormData.name] = src;
      } else {
        let newArray = Array.isArray(src) ? [...src] : [src];
        this.localItem[this.localFormData.name] = newArray;
      }
    },
    async updateFileNames(names) {
      this.localFormData.fileNames = names;
      this.$set(this.localFormData, "fileNames", names);
      this.$forceUpdate();
    },
    updateFileRemove(fileKey) {
      if (Array.isArray(this.localFormData.value)) {
        this.localItem[this.localFormData.name].splice(fileKey, 1);
        this.localFormData.fileNames.splice(fileKey, 1);
      } else {
        this.localItem[this.localFormData.name] = "";
        this.localFormData.fileNames = [];
      }
    }
  },
  data() {
    return {
      defaultFormData: {
        value: [],
        text: "File upload field",
        allowedFormatsText: "Allowed formats",
        allowedFormats: ["pdf", "doc"],
        fileNames: [],
        placeholder: "File upload field",
        name: "File upload field",
        fieldName: "FileUploadField",
        readonly: false,
        returnString: false,
        showPreview: true,
        multiple: false,
        required: false,
        class: ""
      }
    };
  }
};
</script>
<style>
.dropZone {
  height: 100%;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px dashed #404040;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #cacaca;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-over {
  background: #cacaca;
  opacity: 0.8;
}
</style>
