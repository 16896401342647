<template>
  <v-card flat>
    <v-row v-for="(data, index) in localItem[localFormData.name]" v-bind:key="index">
      <v-col cols="6">
        <v-chip>{{ $t("products.form.productPointsLabel") }}{{ data.modelName }} :</v-chip>
      </v-col>
      <v-col cols="6">
        <validation-provider
          :vid="localFormData.name"
          v-slot="{ errors }"
          :name="localFormData.text"
          :rules="localFormData.rules"
        >
          <v-text-field
            autocomplete="off"
            v-model="data.points"
            :placeholder="localFormData.placeholder"
            :label="localFormData.text"
            :name="localFormData.text"
            :error-messages="errors"
            clearable
            solo
          >
          </v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  name: "ProductField",
  mixins: [validate],
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    },
    invalid: {
      required: false
    },
    formData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    localFormData() {
      return this.formData;
    },
    localItem() {
      return this.item;
    }
  },
  methods: {},
  data() {
    return {};
  }
};
</script>
