<template>
  <validation-provider
    vid
    v-slot="{ errors }"
    :name="localFormData.name"
    :rules="localFormData.rules"
  >
    <v-row no-gutters class="no-drag" @click="elementClicked(localFormData)">
      <v-col cols="12" class="align-start justify-content-start">
        <div class="caption text-left">{{ localFormData.text }}</div>
      </v-col>
      <v-radio-group v-model="localItem[localFormData.name]" :error-messages="errors" row>
        <v-radio
          v-for="item in localFormData.items"
          v-bind:key="item"
          :readonly="localIlocalFormDatatem.readonly"
          :value="item"
          :multiple="localFormData.multiple"
          :placeholder="localFormData.placeholder"
          :label="item"
          class="mr-2 mt-0"
        ></v-radio>
      </v-radio-group> </v-row
  ></validation-provider>
</template>
<script>
import validate from "@/mixins/validationMixin";
export default {
  components: {},
  name: "RadioField",
  mixins: [validate],
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    formData: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      required: false
    }
  },
  computed: {
    localItem() {
      return this.item || this.defaultFormData;
    },
    localFormData() {
      return this.formData || this.defaultFormData;
    }
  },
  methods: {
    elementClicked(element) {
      this.$emit("clicked-element-update", element);
    }
  },
  data() {
    return {
      defaultFormData: {
        value: "",
        text: "Radio button field",
        placeholder: "Radio button field",
        name: "Radio button field",
        fieldName: "RadioField",
        type: "RadioField",
        readonly: false,
        multiple: false,
        items: ["Radio 1", "Radio 2"],
        required: false,
        class: ""
      }
    };
  }
};
</script>
